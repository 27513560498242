<template>
  <div class="tile">
    <div class="tile__row">
      <div class="left-section">
        <slot name="left-section">
          <div class="tile__header">
            <div v-if="hasImage" class="tile__image-section">
              <slot name="imageSection" />
            </div>
            <slot name="title"></slot>
          </div>
        </slot>
      </div>
      <div class="tile__right-section">
        <slot name="right-section"> </slot>
        <div v-click-away="onClickAway" @click="menuVisible = true" v-if="hasMenu" class="dots-wrapper">
          <slot name="button"> </slot>
          <img class="options" src="@/assets/icons/dots.svg" />
        </div>
        <div v-if="menuVisible" class="tile__menu">
          <slot name="menu" />
        </div>
      </div>
    </div>
    <div class="tile__desc">
      <slot name="desc"> </slot>
    </div>
    <div class="tile__desc">
      <slot name="footer"> </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { directive } from 'vue3-click-away'

export default defineComponent({
  directives: {
    ClickAway: directive
  },
  setup(props, context) {
    const hasImage = computed(() => {
      return context.slots.imageSection
    })
    const menuVisible = ref(false)

    function onClickAway() {
      menuVisible.value = false
    }
    const hasMenu = computed(() => {
      return context.slots.menu
    })
    return { hasImage, menuVisible, onClickAway, hasMenu }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
p
  margin: 0
.tile
  padding: 1rem
  background: $white
  min-width: 264px
  &__row
    display: flex
    width: 100%
    justify-content: space-between
    margin-bottom: 0.5rem
  &__image-section
    margin-bottom: 0.5rem
  &__footer
    p
      color: map-get($greyShades, 'greyShade60')
  @media( max-width: $md-breakpoint)
    width: 100%
  &__desc
    font-size: 12px
    & > p
      margin-bottom: 0.5rem
  &__right-section
    display: flex
    align-items: baseline
    justify-content: center
    position: relative
    .options
      margin-left: 0.5rem
      cursor: pointer
  &__menu
    position: absolute
    background-color: $white
    box-shadow: 0px 4px 13px rgba(14, 30, 54, 0.1)
    border-radius: 3px
    left: 1.2rem
    z-index: 200
    @media( max-width: $md-breakpoint)
      left: -2rem
.dots-wrapper
  padding: 0 0.5rem 0 0
  cursor: pointer
</style>
<style lang="sass">
.tile__menu ul
  list-style: none
  margin: 0
  padding: 0
  li
    padding: 0.5rem 1rem 0.5rem 1rem
    cursor: pointer
    transition: all .2s
    &:hover
      background-color: map-get($greyShades, 'greyShade10')
</style>
