<template>
  <section>
    <div class="top-right-button">
      <slot name="top-right-button" />
    </div>
    <slot name="header" />
    <div class="content">
      <slot name="content" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DashboardSection'
})
</script>

<style lang="sass">
section
  padding: 1.5rem 3.25rem
  @media( max-width: $md-breakpoint)
    padding: 1.5rem
.top-right-button
  display: flex
  justify-content: flex-start
  margin-bottom: 1.5rem
  & > *
    max-width: 12rem
    margin: 0.5rem
.content
  display: flex
  flex-direction: column
</style>
