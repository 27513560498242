
import CustomButton from '@/components/atom/CustomButton.vue'
import { ref, defineComponent, computed, onMounted } from 'vue'
import authApi from '@/api/Authenticated'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import Tile from '@/components/atom/Tile.vue'
import moment from 'moment'
import { useStore } from '@/store'
import SmallButton from '@/components/atom/SmallButton.vue'
import { useRoute } from 'vue-router'
import commonString from '@/utils/commonStrings'
import commonFunctions from '@/utils/commonFunctions'
export default defineComponent({
  name: 'Documents',
  components: {
    CustomButton,
    DashboardSection,
    Tile,
    SmallButton
  },
  setup() {
    const docs = ref()
    const store = useStore()
    const route = useRoute()
    const subcontractorId = ref()
    subcontractorId.value = route.params.id
    const userName = computed(() =>
      store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : 'User name'
    )
    function filterDate(date: Date) {
      return moment(date).format('D/MM/YY')
    }
    async function getDocuments() {
      const res = await authApi.getSubcontractorDocuments(subcontractorId.value)
      docs.value = res
      commonFunctions.getRequiredDocuments(docs.value, 'NDA', 'MSA', 'SOW')
    }
    onMounted(getDocuments)
    async function deleteDoc(subcontractorId: number, documentId: number) {
      await authApi.deleteSubcontractorDocument(subcontractorId, documentId).then(function () {
        const docIndex = docs.value.documents.findIndex((e: any) => e.documentId === documentId)
        if (docIndex !== -1) {
          docs.value.documents.splice(docIndex, 1)
        }
        getDocuments()
      })
    }

    async function downloadDoc(subcontractorId: number, documentId: number) {
      if (documentId != null) {
        const res = await authApi.downloadSubcontractorDocument(subcontractorId, documentId)
        if (res) {
          window.open(res.link, '_blank')
        }
      }
    }
    return { docs, filterDate, open, deleteDoc, userName, subcontractorId, commonString, downloadDoc }
  }
})
