
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['onClick'],
  props: {
    label: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('onClick')
    }
    return { onClick }
  }
})
