<template>
  <div>
    <button @click="onClick">{{ label }}</button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['onClick'],
  props: {
    label: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('onClick')
    }
    return { onClick }
  }
})
</script>

<style lang="sass" scoped>
button
  background-color: $purple
  color: $white
  border: none
  padding: 4px 8px
  font-family: inherit
  font-weight: bold
  cursor: pointer
</style>
